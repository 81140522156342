import { Module } from 'vuex';
import API from '@/plugins/axios';
import { Questionnaire, Tenants } from '@/types/types';
import { i18n } from '@/main';
import { roles, ROLES_MAIN } from '@/utils/utils';

export interface AppState {
  questionnaires: Questionnaire[];
  tenants: Tenants;
  activeTenant: string;
  changingTenant: boolean;
  tenantHeaderSet: boolean;
  tenantError: string;
}

const App: Module<AppState, {}> = {
  state: {
    questionnaires: [],
    tenants: {},
    activeTenant: '',
    changingTenant: false,
    tenantHeaderSet: false,
    tenantError: '',
  },
  actions: {
    setQuestionnaires: ({ commit }, payload: AppState['questionnaires']) => {
      commit('setQuestionnaires', payload);
    },
    getTenants: async ({ commit, dispatch, state, getters }) => {
      if (!getters.tnc_accepted) return;
      if (state.activeTenant) {
        if (
          Object.values(state.tenants).find(t => t.key === state.activeTenant)?.role === roles.TEACHER &&
          ![roles.ADMIN, roles.COUNSELLOR].includes(getters.activeTenantRole)
        ) {
          commit('setTenantError');
        } else {
          commit('setTenantHeader');
        }
      }
      try {
        const { data } = await API.get<Tenants>('v0/auth/user/classter_users/');
        commit('setTenants', data);
        const tenantValues = Object.values(data);
        if (
          !state.activeTenant &&
          tenantValues.length === 1 &&
          tenantValues[0].role !== roles.TEACHER &&
          ![roles.ADMIN, roles.COUNSELLOR].includes(getters.activeTenantRole)
        ) {
          dispatch('setActiveTenant', tenantValues[0].key);
        }
      } catch (e) {
        console.error(e);
      }
    },
    setActiveTenant: ({ commit }, payload: AppState['activeTenant']) => {
      commit('setActiveTenant', payload);
    },
    setChangingTenant: ({ commit }, payload: AppState['changingTenant']) => {
      commit('setChangingTenant', payload);
    },
    logout: ({ commit }) => {
      commit('appLogout');
    },
  },
  mutations: {
    setQuestionnaires: (state, payload: AppState['questionnaires']) => {
      state.questionnaires = payload;
    },
    setTenants: (state, payload: AppState['tenants']) => {
      state.tenants = payload;
    },
    setActiveTenant: (state, payload: AppState['activeTenant']) => {
      if (!state.tenantHeaderSet) state.tenantHeaderSet = true;
      state.activeTenant = payload;
      state.tenantError = '';
      state.changingTenant = false;
    },
    setChangingTenant: (state, payload: AppState['changingTenant']) => {
      state.changingTenant = payload;
    },
    setTenantHeader: state => {
      state.tenantHeaderSet = true;
    },
    setTenantError: state => {
      state.tenantError = i18n.t('TenantsDialog.insufficientPermissions') as string;
      state.changingTenant = true;
    },
    appLogout: state => {
      state.questionnaires = [];
    },
  },
  getters: {
    questionnaires: state => state.questionnaires,
    tenants: state => state.tenants,
    activeTenant: state => state.activeTenant,
    changingTenant: state => state.changingTenant,
    tenantHeaderSet: state => state.tenantHeaderSet,
    tenantError: state => state.tenantError,
    activeTenantRole: (state, _getters, _rootState, rootGetters) => {
      const userRole = rootGetters.userData.role;
      const tenantRole = Object.values(state.tenants).find(t => t.key === state.activeTenant)?.role || '';
      return ROLES_MAIN.includes(userRole) ? userRole : tenantRole;
    },
  },
};

export default App;
