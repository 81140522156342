export const getToken = () => {
  return localStorage.getItem('access') || '';
};

export const imageData = {
  improvement: {
    file: 'improvement',
    name: 'Improvement',
    image: '/skills/improvement.svg',
    imageNoBg: '/skillsNoBackground/improvement.svg',
    color: '#5BDEBC',
  },
  finance: {
    file: 'finance',
    name: 'Finance',
    image: '/skills/finance.svg',
    imageNoBg: '/skillsNoBackground/finance.svg',
    color: '#60D4F3',
  },
  engineering: {
    file: 'engineering',
    name: 'Engineering',
    image: '/skills/engineering.svg',
    imageNoBg: '/skillsNoBackground/engineering.svg',
    color: '#FD836B',
  },
  it: {
    file: 'it',
    name: 'IT',
    image: '/skills/it.svg',
    imageNoBg: '/skillsNoBackground/it.svg',
    color: '#B3A2F0',
  },
  crafts: {
    file: 'crafts',
    name: 'Crafts',
    image: '/skills/crafts.svg',
    imageNoBg: '/skillsNoBackground/crafts.svg',
    color: '#FCD370',
  },
  beauty: {
    file: 'beauty',
    name: 'Beauty',
    image: '/skills/beauty.svg',
    imageNoBg: '/skillsNoBackground/beauty.svg',
    color: '#F5D0B3',
  },
  farming: {
    file: 'farming',
    name: 'Farming',
    image: '/skills/farming.svg',
    imageNoBg: '/skillsNoBackground/farming.svg',
    color: '#9DD464',
  },
  en: {
    file: 'en',
    name: 'Language EN',
    image: '/skills/en.svg',
    imageNoBg: '/skillsNoBackground/en.svg',
    color: '#F397CF',
  },
  mt: {
    file: 'mt',
    name: 'Language MT',
    image: '/skills/mt.svg',
    imageNoBg: '/skillsNoBackground/mt.svg',
    color: '#F397CF',
  },
  misc: {
    file: 'misc',
    name: 'Misc',
    image: '/skills/misc.svg',
    imageNoBg: '/skillsNoBackground/misc.svg',
    color: '#ED5260',
  },
};

export const roles = {
  STUDENT: 'Student', // only perosnal
  TEACHER: 'Teacher', // disabled
  STUDENT_MENTOR: 'Student Mentor', // institute
  DEPUTY_INSTITUTE_DIRECTOR: 'Deputy Institute Director', // institute
  INSTITUTE_DIRECTOR: 'Institute Director', // institute
  RESEARCHER: 'Researcher / Assistant Researcher', // user role
  PRINCIPAL: 'Principal / Deputy Principal', // user role
  COUNSELLOR: 'Counsellor', // user role
  ADMIN: 'Administrator', // user role
} as const;

export const ROLES_MAIN = [roles.ADMIN, roles.COUNSELLOR, roles.RESEARCHER, roles.PRINCIPAL];
export const ROLES_ANSWERING_PSY2 = [roles.STUDENT_MENTOR, roles.INSTITUTE_DIRECTOR, roles.COUNSELLOR, roles.ADMIN];
export const ROLES_CREATE_EDIT_NOTES = [roles.STUDENT_MENTOR, roles.COUNSELLOR, roles.ADMIN];
export const ROLES_VIEW_NOTES = [
  roles.STUDENT_MENTOR,
  roles.INSTITUTE_DIRECTOR,
  roles.DEPUTY_INSTITUTE_DIRECTOR,
  roles.PRINCIPAL,
  roles.COUNSELLOR,
  roles.ADMIN,
];
export const ROLES_VIEW_ANALYSIS_RESULTS = [
  roles.STUDENT_MENTOR,
  roles.INSTITUTE_DIRECTOR,
  roles.DEPUTY_INSTITUTE_DIRECTOR,
  roles.RESEARCHER,
  roles.PRINCIPAL,
  roles.COUNSELLOR,
  roles.ADMIN,
];
// export const ROLES_EDIT_STUDENT_RESPONSES_PSY2 = [];
export const ROLES_VIEW_STUDENT_RESPONSES_PSY = [
  roles.INSTITUTE_DIRECTOR,
  roles.DEPUTY_INSTITUTE_DIRECTOR,
  roles.COUNSELLOR,
  roles.ADMIN,
  roles.STUDENT_MENTOR,
  roles.PRINCIPAL,
];
export const ROLES_VIEW_STUDENT_RESPONSES_DEM = [
  // roles.STUDENT, ???
  roles.INSTITUTE_DIRECTOR,
  roles.DEPUTY_INSTITUTE_DIRECTOR,
  roles.COUNSELLOR,
  roles.ADMIN,
  roles.STUDENT_MENTOR,
  roles.PRINCIPAL,
  roles.RESEARCHER,
];
export const ROLES_VIEW_STUDENT_DATA = [
  // roles.STUDENT,
  roles.INSTITUTE_DIRECTOR,
  roles.DEPUTY_INSTITUTE_DIRECTOR,
  roles.COUNSELLOR,
  roles.ADMIN,
  roles.STUDENT_MENTOR,
  roles.PRINCIPAL,
];

export function formatFileSize(number: number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}
