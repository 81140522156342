import { Module } from 'vuex';
import { Psychometrics2Question } from '@/views/Employee/Psychometrics2/types';

export interface QuestionsState {
  questionNumber: number;
  questionsLength: number;
  finished: boolean;
  closing: boolean;
  isCompleted: boolean;
  questions: Psychometrics2Question[];
  predictors: string[];
  predictorNumber: number;
  activeQuestionsLength: number;
}

const Questions: Module<QuestionsState, {}> = {
  state: {
    questionNumber: 0,
    questionsLength: 0,
    finished: false,
    closing: false,
    isCompleted: false,
    questions: [],
    predictors: [],
    predictorNumber: 0,
    activeQuestionsLength: 0,
  },
  actions: {
    updateQuestionsState: ({ commit }, payload: Partial<QuestionsState>) => {
      commit('updateQuestionsState', payload);
    },
    changeActivePredictor: ({ commit, state }, val: string) => {
      const payload = {
        predictorNumber: state.predictors.indexOf(val),
        questionNumber: 0,
      };
      commit('updateQuestionsState', payload);
    },
    logout: ({ commit }) => {
      commit('appLogout');
    },
  },
  mutations: {
    updateQuestionsState: (state, payload: Partial<QuestionsState>) => {
      Object.assign(state, {}, state, payload);
    },
    appLogout: state => {
      Object.assign(
        state,
        {},
        {
          questionNumber: 0,
          questionsLength: 0,
          finished: false,
          closing: false,
          isCompleted: false,
          questions: [],
          predictors: [],
          predictorNumber: 0,
          activeQuestionsLength: 0,
        }
      );
    },
  },
  getters: {
    questionsState: state => state,
    questionNumber: state => state.questionNumber,
    questions: state => state.questions,
    predictors: state => state.predictors,
    predictorNumber: state => state.predictorNumber,
    activePredictor: state => state.predictors[state.predictorNumber],
    activeQuestionsLength: state =>
      state.questions.filter(question => question.predictor_name === state.predictors[state.predictorNumber]).length,
  },
};

export default Questions;
