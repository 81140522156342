import { Module } from 'vuex';
import API from '../plugins/axios';
import { Question, Radio, TextArea, ReorderData, FieldTypes } from '../types/types';

export interface QuestionBuilderState {
  questions: Question[];
  predictors: any[];
  activeQuestion: Question | undefined;
}

const QuestionBuilder: Module<QuestionBuilderState, {}> = {
  state: {
    questions: [],
    predictors: [],
    activeQuestion: undefined,
  },
  actions: {
    getQBQuestions: async ({ commit }, payload: number) => {
      // 4
      const { data } = await API.get<Question[]>(`/v1/prosys/councillor/question_builder/${payload}/`);
      commit('setQBQuestions', data);
    },
    getQBPredictors: async ({ commit }) => {
      // 1
      const { data } = await API.get<any[]>(`/v1/prosys/councillor/predictors/`);
      commit('setQBPredictors', data);
    },
    setActiveQBQuestion: ({ commit }, payload: { question_id: number | 'new'; predictor_id: number }) => {
      commit('setActiveQBQuestion', payload);
    },
    updateQBQuestion: async ({ commit }, payload: { question: Question; questionnaire_id: number }) => {
      const question = payload.question;

      if (question.fields[0].type === FieldTypes.Radio) {
        question.fields[0].text_value = question.fields[0].text_value.length
          ? JSON.stringify(question.fields[0].text_value)
          : '';
        question.fields[0].maltese_text_value = question.fields[0].maltese_text_value.length
          ? JSON.stringify(question.fields[0].maltese_text_value)
          : '';
      }

      if (question.id) {
        // 4
        const { data } = await API.patch<Question>(
          `/v1/prosys/councillor/question_builder/${payload.questionnaire_id}/`,
          payload.question
        );
        commit('updateQBQuestion', data);
      } else {
        delete (payload.question as any).id;
        delete (payload.question as any).predictor_name;
        // 4
        const { data } = await API.post<Question>(
          `/v1/prosys/councillor/question_builder/${payload.questionnaire_id}/`,
          payload.question
        );
        commit('updateQBQuestion', data);
      }
    },
    deleteQBQuestion: async ({ commit }, payload: { question_id: number; questionnaire_id: number }) => {
      // 4
      await API.delete(`/v1/prosys/councillor/question_builder/${payload.questionnaire_id}/${payload.question_id}/`);
      commit('deleteQBQuestion', payload);
    },
    updateQuestionBuilderState: ({ commit }, payload: Partial<QuestionBuilderState>) => {
      commit('updateQuestionBuilderState', payload);
    },
    reorderItem: ({ commit }, payload: ReorderData) => {
      commit('reorderItem', payload);
    },
    logout: ({ commit }) => {
      commit('appLogout');
    },
  },
  mutations: {
    setQBQuestions: (state, payload: Question[]) => {
      state.questions = payload;
      state.activeQuestion = undefined;
    },
    setQBPredictors: (state, payload: any[]) => {
      state.predictors = payload;
    },
    setActiveQBQuestion: (
      state,
      payload: { question_id: number | 'new_multiple_choice' | 'new_open_ended'; predictor_id: number }
    ) => {
      if (payload.question_id === 'new_multiple_choice') {
        state.activeQuestion = {
          id: 0,
          group_id: payload.predictor_id,
          question: '',
          maltese_question: '',
          fields: [{ type: FieldTypes.Radio } as Radio],
          predictor_name: '',
          follow_up: '',
          maltese_follow_up: '',
          cues: '',
          maltese_cues: '',
          order: 0,
        };
      } else if (payload.question_id === 'new_open_ended') {
        state.activeQuestion = {
          id: 0,
          group_id: payload.predictor_id,
          question: '',
          maltese_question: '',
          fields: [
            {
              type: FieldTypes.TextArea,
              text_value: '',
              maltese_text_value: '',
            } as TextArea,
          ],
          predictor_name: '',
          follow_up: '',
          maltese_follow_up: '',
          cues: '',
          maltese_cues: '',
          order: 0,
        };
      } else {
        state.activeQuestion = state.questions.find(question => question.id === payload.question_id);
      }
    },
    updateQBQuestion: (state, payload: Question) => {
      const index = state.questions.findIndex(question => question.id === payload.id);
      if (index !== -1) {
        state.questions[index] = payload;
      } else {
        state.questions.push(payload);
      }
      state.activeQuestion = payload;
      state.questions = [...state.questions];
    },
    deleteQBQuestion: (state, payload: { question_id: number; questionnaire_id: number }) => {
      state.questions = state.questions.filter(question => question.id !== payload.question_id);
    },
    updateQuestionBuilderState: (state, payload: Partial<QuestionBuilderState>) => {
      Object.assign(state, {}, state, payload);
    },
    reorderItem: (state, { moved }: ReorderData) => {
      state.questions = state.questions.map(question => {
        if (question.predictor_name !== moved.element.predictor_name) {
          return question;
        }
        if (question.id === moved.element.id) {
          return {
            ...question,
            order: moved.newIndex,
          };
        }
        if (moved.newIndex > moved.oldIndex) {
          if (question.order > moved.oldIndex && question.order <= moved.newIndex) {
            return {
              ...question,
              order: question.order - 1,
            };
          }
          return question;
        } else {
          if (question.order < moved.oldIndex && question.order >= moved.newIndex) {
            return {
              ...question,
              order: question.order + 1,
            };
          }
          return question;
        }
      }) as Question[];
    },
    appLogout: state => {
      Object.assign(
        state,
        {},
        {
          questions: [],
        }
      );
    },
  },
  getters: {
    getQBQuestions: state => state.questions,
    getQBPredictors: state => state.predictors,
    getActiveQBQuestion: state => state.activeQuestion,
    QuestionBuilderState: state => state,
    // questionNumber: state => state.questionNumber,
  },
};

export default QuestionBuilder;
