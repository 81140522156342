var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AzureComponent',{attrs:{"forceLogin":true},on:{"logged":_vm.onLoggedIn}},[_c('v-app',[(_vm.tosResolved)?[(!_vm.tosAccepted)?[(_vm.tosRefused)?_c('TOSRefused'):_c('TOSDialog',{on:{"tos-refused":function($event){_vm.tosRefused = true}}})]:[_c('tenants-dialog'),(_vm.role)?[_c('router-view',{attrs:{"name":"header"}}),_c('router-view',{attrs:{"name":"sidebar"}}),_c('v-main',{class:( _obj = {
              'grey lighten-5': !_vm.$vuetify.theme.dark && (!_vm.bgColor || _vm.bgColor === 'default')
            }, _obj[("bg-" + _vm.bgColor + "-main")] = !_vm.$vuetify.theme.dark && _vm.bgColor, _obj )},[_c('router-view',{attrs:{"name":"subheader"}}),_vm._l((_vm.snackbars),function(data,i){return [_c('v-snackbar',{key:data.id,staticClass:"snackbar",style:({ 'padding-bottom': ((i * 60) + "px") }),attrs:{"timeout":data.duration,"color":data.type},on:{"input":function($event){return _vm.removeSnackbar(data.id)}},scopedSlots:_vm._u([(data.closable != false)?{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.removeSnackbar(data.id)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}:null],null,true),model:{value:(data.open),callback:function ($$v) {_vm.$set(data, "open", $$v)},expression:"data.open"}},[(data.icon)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(data.icon))]):_vm._e(),_vm._v(" "+_vm._s(data.text)+" ")],1)]}),(_vm.tenantHeaderSet)?_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1):_vm._e()],2),_c('v-divider'),_c('v-footer',{class:( _obj$1 = {
              'grey lighten-5': !_vm.$vuetify.theme.dark && (!_vm.bgColor || _vm.bgColor === 'default')
            }, _obj$1[("bg-" + _vm.bgColor + "-light")] = !_vm.$vuetify.theme.dark && _vm.bgColor, _obj$1 ),attrs:{"app":"","padless":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('img',{attrs:{"src":require("./assets/MCAST.png"),"alt":"mcast-logo","width":"100px"}})])],1)]:_vm._e()]]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }