export interface Questionnaire {
  id: number;
  type: number;
  title: string;
  maltese_title: string;
  demographic: boolean;
  is_ps2: boolean;
  evaluation: Array<{
    id: number;
    is_completed: boolean;
    progress: string;
    created_at: string;
    additional_note: string;
  }>;
  created_at: string;
  updated_at: string;
}

export interface Question {
  id: number;
  group_id: number;
  predictor_name: string;
  order: number;
  question: string;
  maltese_question: string;
  fields: Array<Radio | Text | TextArea | Scale | ScaleText>;
  follow_up: string;
  maltese_follow_up: string;
  cues: string;
  maltese_cues: string;
}

export interface Field {
  id: number;
  dependant_field_id: number | null;
  show_if: string | null;
  label: string;
  maltese_label: string;
  question: number;
  created_at: string;
  updated_at: string;
  required: boolean;
  numeric: boolean;
  answer_fields: Array<{
    value: number;
    text_value: string;
    id?: number;
  }>;
}

export enum FieldTypes {
  Radio = 'RADIO',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  Scale = 'SCALE',
  ScaleText = 'SCALE_TEXT',
}

export interface Radio extends Field {
  type: FieldTypes.Radio;
  text_value: string | string[];
  maltese_text_value: string | string[];
  value: number;
  inline: boolean;
}

export interface Text extends Field {
  type: FieldTypes.Text;
  text_value: string;
  maltese_text_value: string;
  value: string;
}

export interface TextArea extends Field {
  type: FieldTypes.TextArea;
  text_value: string;
  maltese_text_value: string;
  value: string;
}

export interface Scale extends Field {
  type: FieldTypes.Scale;
  text_value: string;
  maltese_text_value: string;
  value: number;
  points: number;
}

export interface ScaleText extends Field {
  type: FieldTypes.ScaleText;
  text_value: string;
  maltese_text_value: string;
  value: number;
  points: number;
}

export interface Construct {
  construct_name: string;
  construct_name_maltese: string;
  risk: boolean;
}

export interface StudentResults {
  id: number;
  user_id: number;
  name: string;
  gender: string;
  age: string;
  level: string;
  school_year: string;
  _class: string;
  disability: boolean;
  employed: boolean;
  education_level: string;
  lives_with_parents: boolean;
  dependants: boolean;
  income: string;
  mother_education: string;
  father_education: string;
  household_income: string;
  questionnaire_title: string;
  questionnaire_risk: boolean;
  questionnaire_score: number;
  predictor_title: string;
  predictor_risk: string;
  predictor_score: number;
  psychometric_risk: boolean;
  psychometric_score: number;
  note_id: number;
  estimated_risk: string;
  is_completed: boolean;
  duration: number;
  finished_at: string;
  created_at: string;
  updated_at: string;
}

interface Evaluation {
  created_at: string;
  id: number;
  is_completed: boolean;
  progress: number;
}

export interface Test {
  created_at: string;
  updated_at: string;
  id: number;
  title: string;
  maltese_title: string;
  type: number;
  demographic: boolean;
  evaluation: Evaluation[];
  is_ps2: boolean;
}

export interface Event {
  created_at: string;
  updated_at: string;
  created_by: string;
  id: number;
  student: number;
  title: string;
  description: string;
  type: string;
  risk_level: string;
  media_file_uuid?: string;
  file_data?: EventFileData;
}

export interface EventFileData {
  original_name: string;
  size: number;
  content_type: string;
  media_file_url: string;
}

export interface Tenants {
  [key: string]: {
    key: string;
    name: string;
    role: string;
  };
}

export interface Predictor {
  id: number;
  name: string;
  maltese_name: string;
}

export type ReorderData = {
  moved: {
    element: Question;
    oldIndex: number;
    newIndex: number;
  };
};
