import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/dayjs';
import '@/plugins/i18n';
import VueI18n from 'vue-i18n';
import en from '@/locales/en';
import mt from '@/locales/mt';
import '@/plugins/snackbar';

Vue.config.productionTip = false;

const messages = {
  en,
  mt,
};

export const i18n = new VueI18n({
  locale: 'en',
  messages,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
