export default {
  cancel: 'Cancel',
  save: 'Save',
  next: 'Next',
  done: 'Done',
  finish: 'Finish',
  exit: 'Exit',
  note: 'Note',
  close: 'Close',
  delete: 'Delete',
  Profile: {
    age: 'Age',
    gender: 'Gender',
  },
  Interests: {
    suggestions: 'Skills Kits Suggestions',
    edit: 'Edit Interests',
    interests: 'Interests',
    description: 'Please enter 1-5 terms which interests You',
    hint: 'Press enter to set each interest',
    label: 'Your interests',
  },
  Questionnaires: {
    questionnaires: 'Questionnaires',
    progress: 'Questionnaire progress',
    questionnaireTemplates: 'QUESTIONNAIRE TEMPLATES',
    activityHistory: 'ACTIVITY HISTORY',
    makeActive: 'Make active',
    edit: 'edit',
    activityScheduler: 'Activity Scheduler',
    schoolYear: 'School year',
    startDate: 'Start date',
    endDate: 'End date',
    deleteQuestionnaire: 'Are you sure you want to delete this questionnaire?',
    TableColumns: {
      school_year: 'School Year',
      start_date: 'Start Date',
      end_date: 'End Date',
      status: 'Status',
      actions: 'Actions',
    },
  },
  Settings: {
    settings: 'Settings',
    appearance: 'Appearance',
    privacy: 'Privacy',
    Appearance: {
      fontSize: 'Font size',
      fontFamily: 'Font family',
      darkMode: 'Dark mode',
      backgroundColor: 'Background color',
      language: 'Language',
      en: 'English',
      mt: 'Maltese',
    },
  },
  Questions: {
    congrats: 'Congratulations, you are done.',
    exit: 'Exit the questionnaire?',
    exitDescription: 'You will be able to come back later',
    exitDescription2: 'and pick up where you left off.',
    snackbarTest: 'Closed test can be resumed at any time',
    snackbarSpace: 'Use Ctrl + Space to go to the Next question',
    required: 'Questions are required',
  },
  Header: {
    contactSupport: 'Contact support',
    changeInstitute: 'Change institute',
    settings: 'Settings',
    signOut: 'Sign out',
  },
  Employee: {
    predictors: 'Predictors',
    groups: 'Groups',
    questionnaries: 'Questionnaries',
    students: 'Students',
    reports: 'Reports',
  },
  rules: {
    required: 'This field is required',
    number: 'This field must be a number',
    date: 'This field must be a date (DD.MM.YYYY)',
    invalid_date: 'Please enter a valid birth date',
  },
  TenantsDialog: {
    title: 'Select institute',
    insufficientPermissions: 'Insufficient permissions for this institute',
    logout: 'Logout',
  },
  QuestionnaireBuilder: {
    title: 'Psychometrics 2 Editor',
    predictors: 'Predictors',
    addNewQuestion: 'Add new question',
    createNewQuestion: 'Create new question',
    selectQuestionType: 'Select question type you would like to create',
    multipleChoice: 'Multiple choice question',
    openEnded: 'Open ended question',
    cuesInfoText: "If you don't enter the cues question text, this box will not appear in the test",
    followupInfoText: "If you don't enter the follow-up question text, this box will not appear in the test",
    reorderingError: 'An error occurred while reordering questions',
  },
  Students: {
    studentResults: 'STUDENT RESULTS',
    demographics: 'DEMOGRAPHICS',
    psychometrics: 'PSYCHOMETRICS',
    groups: 'Groups',
    all: 'All',
    Selection: {
      name: 'Name',
      _class: 'Class',
      gender: 'Gender',
      age: 'Age',
      school_year: 'School year',
      disability: 'Disability',
      employed: 'Employed',
      education_level: 'Education level',
      lives_with_parents: 'Lives with parents',
      dependants: 'People depending',
      income: 'Personal income',
      mother_education: "Mother's education level",
      father_education: "Father's education level",
      household_income: 'Household income',
      predictors: 'Predictors',
      questionnaires: 'Questionnaires',
      psychometric_risk: 'Psychometrics risk',
      estimated_risk: 'Estimated risk',
      predictor_title: 'Predictor title',
      predictor_risk: 'Predictor risk',
      predictor_score: 'Predictor score',
      questionnaire_title: 'Questionnaire title',
      questionnaire_risk: 'Questionnaire risk',
      questionnaire_score: 'Questionnaire score',
      psychometric_score: 'Psychometrics score',
      note: 'Note',
    },
    Filters: {
      yes: 'YES',
      no: 'NO',
      none: 'None',
      dont_know: "I don't know",
      male: 'Male',
      female: 'Female',
      prefer_not_to_say: 'Prefer not to say',
      no_to_low_risk: 'No to Low Risk',
      medium_risk: 'Medium Risk',
      high_risk: 'High Risk',
      no_formal_qualification: 'No formal school-leaving qualification',
      school_leaving_certificate: 'School leaving Certificate',
      mqf3: 'MQF Level 3 or Ordinary Levels',
      mqf4: 'MQF Level 4 or Advanced Levels',
      mqf5: 'MQF Level 5',
      mqf6: 'MQF Level 6 (Bachelor Degree)',
      mqf7: 'MQF Level 7 (Post-Graduate/Masters Degree)',
      mqf8: 'MQF Level 8 (Doctorate Degree)',
      no_income: 'No income',
      below10: 'Below 10.000 €',
      below15: '10.000 - 15.000 €',
      below20: '15.000 - 20.000 €',
      below25: '20.000 - 25.000 €',
      below30: '25.000 - 30.000 €',
      above30: 'Above 30.000 €',
      below12700: 'Below 12.700 €',
      below21200: '12.700 - 21.200 €',
      below28700: '21.200 - 28.700 €',
      below60: '28.700 - 60.000 €',
      above60: 'Above 60.000 €',
      low_income: 'Low (below 15.000,00 €)',
      middle_income: 'Middle (in 15.000,00 € - 37.000,00 € range)',
      high_income: 'High (above 37.000,00 €)',
      low_household_income: 'Low (below 30.000,00 €)',
      middle_household_income: 'Middle (in 30.000,00 € - 75.000,00 € range)',
      high_household_income: 'High (above 75.000,00 €)',
    },
    activity: [
      { text: 'Show all students', value: null },
      { text: 'Show only active students', value: 'yes' },
      { text: 'Show only not active students', value: 'no' },
    ],
    clearFilters: 'Clear all filters',
    clearSelections: 'Clear all selections',
    exportToCsv: 'Export to CSV',
    searchStudents: 'Search students',
    viewNote: 'View Note',
  },
  StudentProfile: {
    riskLevel: 'Risk Level',
    dob: 'DOB',
    class: 'Class',
    gender: 'Gender',
    eduLevel: 'Edu Level',
    conditions: 'Conditions',
    employer: 'Employer',
    livesWithParents: 'Lives with parents/guardians',
    fatherEduLevel: "Father's/guardian's Edu Level",
    motherEduLevel: "Mother's/guardian's Edu Level",
    personalIncome: 'Personal income',
    householdIncome: 'Household income',
    Tabs: {
      personal: 'Personal',
      results: 'Results',
      events: 'Events',
    },
    Events: {
      studentsEvents: 'STUDENTS EVENTS',
      search: 'Search',
      listEmpty: 'The list is empty',
      estimatedRisk: 'Estimated risk',
      riskLevel: 'Risk Level',
      LOW: 'LOW',
      MEDIUM: 'MEDIUM',
      HIGH: 'HIGH',
      noToLowRisk: 'No to Low Risk',
      mediumRisk: 'Medium Risk',
      highRisk: 'High Risk',
      selectEventType: 'Select Event Type',
      selectRiskFactor: 'Select risk factor',
      title: 'Title',
      description: 'Description',
      uploadError: 'An error occurred while uploading selected file',
      EventTypes: {
        Custom: 'Custom',
        'Estimated Risk': 'Estimated Risk',
      },
    },
  },
  QuestionnaireViewer: {
    active: 'Active',
  },
  Psychometrics2: {
    questionnaires: 'Questionnaires',
    psychometrics2: 'Psychometrics 2',
    riskEstimate: 'Risk Estimate',
    question: 'Question',
    of: 'of',
    finish: 'Finish',
    overview: 'Overview',
    previous: 'Previous',
    next: 'Next',
    cues: 'Cues',
    follow_up: 'Follow-up questions',
    answer: 'Answer',
    infoText:
      'Please choose the behaviors you observed in each category. Please also note any additional observations falling within this category / construct. Please choose one degree for the construct (low, medium, high) based on the information. The decision should be made according to cumulative evidence.',
    riskEstimateText:
      'Please give your risk estimate for the previous predictor. You will be able to edit this estimate once you finish all of the predictors.',
    riskFactorsOverview: 'Risk factors overview',
    predictor: 'Predictor',
    riskFactor: 'Risk Factor',
    additionalNote: 'Additional note',
    nextPredictor: 'Next predictor',
    finishAndNext: 'Finish and go to next one?',
    finishInfoText:
      'Finish this line of questions and give the risk factor assesment. You will be able to come back and change this.',
    finishTest: 'Finish test',
    cancel: 'Cancel',
    yes: 'Yes',
    atRisk: 'At risk',
    notAtRisk: 'Not at risk',
    skip: 'Skip',
    none: 'None',
    notePlaceholder: 'Select to enter questionnaire note',
    Answer: {
      Low: 'Low',
      Medium: 'Medium',
      High: 'High',
    },
  },
};
