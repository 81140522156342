import axios from 'axios';
import AzureAuth from './AzureAuth';
import store from '@/store';

export const BASE_URL = process.env.VUE_APP_API_URL;

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(async config => {
  const token = await AzureAuth.getToken();
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

API.interceptors.request.use(async config => {
  if (store.getters.activeTenant) config.headers['x-tenant-uuid'] = store.getters.activeTenant;
  return config;
});

export default API;

export interface APIPagination<T> {
  count: number;
  results: T;
}
