import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import App, { AppState } from './app';
import User, { UserState } from './user';
import Questions, { QuestionsState } from './questions';
import QuestionBuilder, { QuestionBuilderState } from './question_builder';
import StudentProfile, { StudentProfileState } from './student_profile';

Vue.use(Vuex);
Vue.config.devtools = true;

interface FullRootState {
  User: UserState;
  App: AppState;
  Questions: QuestionsState;
  QuestionBuilderState: QuestionBuilderState;
  StudentProfileState: StudentProfileState;
}

interface RootState {
  version: string;
}

export default new Vuex.Store({
  state: {
    version: '0.0.1',
  },
  mutations: {},
  actions: {},
  modules: {
    User,
    App,
    Questions,
    QuestionBuilder,
    StudentProfile,
  },
  plugins: [
    createPersistedState({
      reducer: (state: RootState) => {
        // eslint-disable-next-line prettier-vue/prettier
        if (((state as unknown) as FullRootState).User.isLoggedIn) {
          return {
            ...state,
            App: {
              ...(state as any).App,
              tenantHeaderSet: false,
              tenantError: '',
            },
          };
        } else {
          return {};
        }
      },
    }),
  ],
});
