import { Module } from 'vuex';

export interface StudentProfileState {
  tests: Test[];
}

export interface Test {
  id: number;
  type: number;
  title: string;
  maltese_title: string;
  demographic: boolean;
  is_ps2: boolean;
  evaluation: Evaluation[];
  created_at: string;
  updated_at: string;
}

export interface Evaluation {
  id: number;
  progress: number;
  is_completed: boolean;
  additional_note: string;
  created_at: string;
}

const StudentProfile: Module<StudentProfileState, {}> = {
  state: {
    tests: [],
  },
  actions: {
    setTests: ({ commit }, payload) => {
      commit('setTests', payload);
    },
  },
  mutations: {
    setTests: (state, payload) => {
      state.tests = payload;
    },
  },
  getters: {
    getTests: state => state.tests,
  },
};

export default StudentProfile;
