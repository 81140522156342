































































import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import { roles } from '@/utils/utils';
import { SavedSnackbar, Snackbar } from './plugins/snackbar';
import AzureAuth from './plugins/AzureAuth';
import AzureComponent from '@/components/Auth/AzureComponent.vue';
import TenantsDialog from '@/components/TenantsDialog.vue';
import TOSDialog from '@/components/TOSDialog.vue';
import TOSRefused from '@/components/TOSRefused.vue';

export default Vue.extend({
  name: 'App',
  components: {
    AzureComponent,
    TenantsDialog,
    TOSDialog,
    TOSRefused,
  },
  data: () => ({
    snackbars: [] as SavedSnackbar[],
    tosRefused: false,
    roles,
  }),
  methods: {
    async onLoggedIn(): Promise<void> {
      await this.$store.dispatch('getCurrentUser');
      await this.$store.dispatch('getSettings');
      await this.$store.dispatch('getTenants');
    },
    createSnackbar(data: Snackbar): void {
      this.snackbars = [...this.snackbars, { ...data, id: uuid(), open: true }];
    },
    removeSnackbar(id: string): void {
      this.snackbars = [...this.snackbars.filter(snackbar => snackbar.id !== id)];
    },
    async getSettings(i: number) {
      if (await AzureAuth.getToken()) {
        await this.$store.dispatch('getSettings');
      } else if (i < 20) {
        setTimeout(() => this.getSettings(i + 1), 500);
      }
    },
    async getTenants(i: number) {
      if (await AzureAuth.getToken()) {
        await this.$store.dispatch('getTenants');
      } else if (i < 20) {
        setTimeout(() => this.getTenants(i + 1), 500);
      }
    },
    async getCurrentUser(i: number) {
      if (await AzureAuth.getToken()) {
        await this.$store.dispatch('getCurrentUser');
      } else if (i < 20) {
        setTimeout(() => this.getCurrentUser(i + 1), 500);
      }
    },
  },
  computed: {
    activeTenant(): string {
      return this.$store.state.App.activeTenant;
    },
    changingTenant(): boolean {
      return this.$store.state.App.changingTenant;
    },
    tosResolved(): boolean {
      return this.$store.getters.tnc_resolved;
    },
    tosAccepted(): boolean {
      return this.$store.getters.tnc_accepted;
    },
    tenantHeaderSet(): boolean {
      return this.$store.getters.tenantHeaderSet;
    },
    role(): string | undefined {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      if (!this.$store.state.User.settings.common) return 'default';
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  watch: {
    async tosAccepted(val) {
      if (val) {
        await this.getSettings(0);
        await this.getTenants(0);
        await this.$store.dispatch('getCurrentUser');
      }
    },
    role(newRole) {
      if (newRole) {
        this.$router.go(0);
      }
    },
  },
  async mounted() {
    this.$root.$on('createSnackbar', this.createSnackbar);
    await this.getCurrentUser(0);
    await this.getSettings(0);
    await this.getTenants(0);
  },
  beforeDestroy() {
    this.$root.$off('createSnackbar', this.createSnackbar);
  },
});
