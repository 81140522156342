import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

export interface SavedSnackbar extends Snackbar {
  open: boolean;
  id: string;
}

export interface Snackbar {
  duration?: number | string;
  icon?: string;
  closable?: boolean;
  text: string | TranslateResult;
  type?: 'info' | 'success' | 'error' | 'warning';
}

const MyPlugin = {
  install(_Vue: typeof Vue) {
    _Vue.mixin({
      methods: {
        $snackbar(data: Snackbar) {
          this.$root.$emit('createSnackbar', data);
        },
      },
    });
  },
};
Vue.use(MyPlugin);
