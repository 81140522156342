import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

let dark = false;
const vuex = localStorage.getItem('vuex');
if (vuex && JSON.parse(vuex).User?.settings?.common?.color_theme === 'Dark') {
  dark = true;
}

export default new Vuetify({
  theme: {
    dark,
    themes: {
      light: {
        primary: '#55B344',
        secondary: '#B0BEC5',
        accent: '#1976D2',
        error: '#B71C1C',
        black: colors.grey.darken2,
        white: '#F4F7F7',
        info: '#1976D2',
      },
      dark: {},
    },
    options: {
      customProperties: true,
      variations: true,
    },
  },
});

export type VForm = Vue & { validate: () => boolean };
