



































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Questionnaire } from '@/types/types';
import { imageData } from '@/utils/utils';
import API from '@/plugins/axios';

export default Vue.extend({
  name: 'Home',
  data: () => ({
    imageData: imageData,
    interestOpen: false,
    tempInterests: [] as string[],
    interests: [] as string[],
    ilpResults: [] as {}[],
    ilpUrl: process.env.VUE_APP_ILP,
  }),
  computed: {
    ...mapGetters(['userData', 'questionnaires']),
    imageUrl() {
      return this.$store.state.User.userData.clusterData?.image
        ? `data:image/png;base64,${this.$store.state.User.userData.clusterData?.image}`
        : this.$store.state.User.userData.image_url;
    },
    userName() {
      let userName = '';
      let initials = '';
      if (this.$store.state.User.userData.clusterData?.givenName) {
        const firstName = this.$store.state.User.userData.clusterData?.givenName;
        const middleName = this.$store.state.User.userData.clusterData?.middleName || '';
        const lastName = this.$store.state.User.userData.clusterData?.surname;
        userName = `${firstName} ${middleName} ${lastName}`;
        initials = `${firstName[0]} ${lastName?.[0]}`;
      } else {
        userName =
          this.$store.state.User.userData.displayName ||
          `${this.$store.state.User.userData.first_name} ${this.$store.state.User.userData.last_name}`;
        initials = `${this.$store.state.User.userData.first_name?.[0]} ${this.$store.state.User.userData.last_name?.[0]}`;
      }
      return [userName, initials];
    },
    CMISUserData() {
      const data = this.$store.state.User.userData.clusterData;
      if (!data || !Object.keys(data).length) {
        return {
          age: '-',
          sex: '-',
          location: '',
          college: '',
          yearOfStudy: '',
        };
      } else {
        const CMISUserData = {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          age: data.dateOfBirth ? `${Math.floor((this as any).$date().diff(data.dateOfBirth, 'year', true))}` : '-',
          sex: data.gender || '-',
          location: data.location?.description || '',
          college: data.dynamicField4?.description || '',
          yearOfStudy: '',
        };
        if (CMISUserData.sex != '-')
          CMISUserData.sex = `${CMISUserData.sex?.[0].toUpperCase()}${CMISUserData.sex.slice(1)}`;
        return CMISUserData;
      }
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    removeInterest(item: string) {
      this.tempInterests.splice(this.tempInterests.indexOf(item), 1);
      this.tempInterests = [...this.tempInterests];
    },
    async createOrUpdateInterests() {
      if (!this.interests.length) {
        // 5
        const { status } = await API.post('v1/prosys/interests/', {
          interests: this.tempInterests,
        });
        if (status == 200) {
          this.interests = [...this.tempInterests];
          this.interestOpen = false;
        }
      } else {
        // 5
        const { status } = await API.patch('v1/prosys/interests/', {
          interests: this.tempInterests,
        });
        if (status == 200) {
          this.interests = [...this.tempInterests];
          this.interestOpen = false;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (this as any).getInterests();
    },
    closeInterestsPanel() {
      this.tempInterests = this.interests;
      this.interestOpen = false;
    },
    async openQuestioner(id: number) {
      const questioner = (this.questionnaires as Questionnaire[]).find(q => q.id == id);
      if (questioner) {
        if (questioner.evaluation.length) {
          this.$router.push(`/questions/${questioner.evaluation[questioner.evaluation.length - 1].id}/`);
        } else {
          // 5
          const { data } = await API.post(`v1/prosys/evaluations/${questioner.id}/`);
          this.$router.push(`/questions/${data.id}/`);
        }
      }
    },
    async getInterests() {
      if (this.interests.length) {
        // 5
        const { data: ilp } = await API.get('v1/prosys/suggestions/');
        if (ilp.results) {
          this.ilpResults = [...ilp.results];
        }
      }
    },
  },
  async mounted() {
    try {
      // 5
      const { data: interests } = await API.get('v1/prosys/interests/');
      if (interests.length) {
        this.interests = [...interests];
        this.tempInterests = [...interests];
        this.interestOpen = false;
      } else {
        this.interestOpen = true;
      }
    } catch {
      this.interestOpen = true;
    }
    // 5
    const { data: questionnaires } = await API.get('v1/prosys/questioners/');
    this.$store.dispatch('setQuestionnaires', questionnaires);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await (this as any).getInterests();
  },
});
