





















import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      navItems: [
        {
          title: 'Communicator',
          href: process.env.VUE_APP_COMMUNICATOR_URL,
          icon: '/icons/icon_communicator.svg',
        },
        {
          title: 'Learning App',
          href: process.env.VUE_APP_LEARNING_URL,
          icon: '/icons/icon_learningapp.svg',
        },
        {
          title: 'ILP',
          href: process.env.VUE_APP_ILP,
          icon: '/icons/icon_ilp.svg',
        },
        {
          title: 'Smartboard',
          href: process.env.VUE_APP_SMARTBOARD_URL,
          icon: '/icons/icon_smartboard.svg',
        },
      ],
    };
  },
});
