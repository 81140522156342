export default {
  cancel: 'Ikkanċella',
  save: 'Ħlief',
  next: 'Li jmiss',
  done: 'Magħmulx',
  finish: 'Spiċċa',
  exit: 'Ħruġ',
  note: 'Nota',
  close: 'Viċin',
  delete: 'Ħassar',
  Profile: {
    age: 'Età',
    gender: 'Sess',
  },
  Interests: {
    suggestions: 'Suġġerimenti tal-Kits tal-Ħiliet',
    edit: 'Edit Interessi',
    interests: 'Interessi',
    description: 'Jekk jogħġbok ikteb 1-5 termini li jinteressak',
    hint: 'Agħfas Enter biex tissettja kull interess',
    label: 'L-interessi tiegħek',
  },
  Questionnaires: {
    questionnaires: 'Kwestjonarji',
    progress: 'Progress tal-kwestjonarju',
    questionnaireTemplates: 'MUDELLI TAL-KWESTJONARJU',
    activityHistory: 'STORJA TAL-ATTIVITÀ',
    makeActive: 'Agħmel attiv',
    edit: 'editja',
    activityScheduler: 'Scheduler tal-Attivitajiet',
    schoolYear: 'Sena skolastika',
    startDate: 'Data tal-bidu',
    endDate: 'Data tat-tmiem',
    deleteQuestionnaire: 'Int żgur li trid tħassar dan il-kwestjonarju?',
    TableColumns: {
      school_year: 'Sena skolastika',
      start_date: 'Data tal-bidu',
      end_date: 'Data tat-tmiem',
      status: 'Status',
      actions: 'Azzjonijiet',
    },
  },
  Settings: {
    settings: 'Issettjar',
    appearance: 'Dehra',
    privacy: 'Privatezza',
    Appearance: {
      fontSize: 'Daqs tat-tipa',
      fontFamily: 'Familja tat-tipa',
      darkMode: 'Mod skur',
      backgroundColor: 'Kulur fl-isfond',
      language: 'Lingwa',
      en: 'Ingliż',
      mt: 'Maltiż',
    },
  },
  Questions: {
    congrats: 'Prosit, int lest.',
    exit: 'Ħruġ mill-kwestjonarju?',
    exitDescription: "Inti tkun kapaċi terga 'lura aktar tard",
    exitDescription2: 'u aqbad fejn telaq.',
    snackbarTest: "Test magħluq jista 'jitkompla fi kwalunkwe ħin",
    snackbarSpace: 'Uża Ctrl + Space biex tmur għall-mistoqsija li jmiss',
    required: 'Mistoqsijiet huma meħtieġa',
  },
  Header: {
    contactSupport: "Appoġġ ta 'Kuntatt",
    changeInstitute: 'Bidla istitut',
    settings: 'Issettjar',
    signOut: 'Sinjal Out',
  },
  Employee: {
    predictors: 'Tbassir',
    groups: 'Gruppi',
    questionnaries: 'Kwestjonarji.',
    students: 'Istudenti',
    reports: 'Rapporti',
  },
  rules: {
    required: 'Dan il-field huwa meħtieġ',
    number: 'Dan il-field għandu jkun numru',
    date: 'Dan il-field għandu jkun data (DD.MM.YYYY)',
    invalid_date: 'Jekk jogħġbok daħħal data tat-twelid valida',
  },
  TenantsDialog: {
    title: 'Agħżel istitut',
    insufficientPermissions: 'Permessi insuffiċjenti għal dan l-istitut',
    logout: 'Oħroġ',
  },
  QuestionnaireBuilder: {
    title: 'Editur Psometrika 2',
    predictors: 'Tbassir',
    addNewQuestion: 'Żid mistoqsija ġdida',
    createNewQuestion: 'Oħloq mistoqsija ġdida',
    selectQuestionType: "Agħżel it-tip ta 'mistoqsija li tixtieq toħloq",
    multipleChoice: 'Mistoqsija dwar l-Għażla Multipla',
    openEnded: 'Mistoqsija miftuħa',
    cuesInfoText: 'Jekk ma tidħolx fit-test tal-mistoqsija tal-cues, din il-kaxxa ma tidhirx fit-test',
    followupInfoText: "Jekk ma tidħolx fit-test tal-mistoqsija ta 'segwitu, din il-kaxxa ma tidhirx fit-test",
    reorderingError: 'Sar żball waqt l-ordni mill-ġdid tal-mistoqsijiet',
  },
  Students: {
    studentResults: 'RIŻULTATI TAL-ISTUDENTI',
    demographics: 'DEMOGRAFIJA',
    psychometrics: 'PSIKOMETRIJA',
    groups: 'Gruppi',
    all: 'Kollha',
    Selection: {
      name: 'Isem',
      _class: 'Klassi',
      gender: 'Sess',
      age: 'Età',
      school_year: 'Sena skolastika',
      disability: 'Diżabilità',
      employed: 'Impjegat',
      education_level: "Livell ta 'Edukazzjoni",
      lives_with_parents: 'Jgħix mal-ġenituri',
      dependants: 'Nies jiddependu',
      income: 'Dħul personali',
      mother_education: "Livell ta 'Edukazzjoni ta' l-Omm",
      father_education: "Livell ta 'Edukazzjoni ta' Missier",
      household_income: 'Dħul fid-djar',
      predictors: 'Tbassir',
      questionnaires: 'Kwestjonarji',
      psychometric_risk: 'Riskju tal-psikometrija',
      estimated_risk: 'Riskju stmat',
      predictor_title: 'Titolu tat-Tbassir',
      predictor_risk: 'Riskju tat-tbassir',
      predictor_score: 'Punteġġ tat-tbassir',
      questionnaire_title: 'Titolu tal-Kwestjonarju',
      questionnaire_risk: 'Riskju tal-kwestjonarju',
      questionnaire_score: 'Punteġġ kwestjonarju',
      psychometric_score: 'Punteġġ tal-psikometrija',
      note: 'Nota',
    },
    Filters: {
      yes: 'IVA',
      no: 'LE',
      none: 'Xejn',
      dont_know: 'Ma nafx',
      male: 'Raġel',
      female: 'Mara',
      prefer_not_to_say: 'Nippreferi ma ngħidx',
      no_to_low_risk: 'Le għal riskju baxx',
      medium_risk: 'Riskju medju',
      high_risk: 'Riskju għoli',
      no_formal_qualification: 'L-ebda kwalifika formali li titlef l-iskola',
      school_leaving_certificate: 'Ċertifikat li jħalli l-iskola',
      mqf3: 'Livell 3 MQF jew livelli ordinarji',
      mqf4: 'MQF Livell 4 jew livelli avvanzati',
      mqf5: 'Livell MQF 5',
      mqf6: "MQF Livell 6 (Grad ta 'Baċellerat)",
      mqf7: "Livell MQF 7 (grad ta 'wara l-gradwat / Masters)",
      mqf8: "MQF Livell 8 (grad ta 'dottorat)",
      no_income: 'Ebda dħul',
      below10: 'Taħt 10.000 €',
      below15: '10.000 - 15.000 €',
      below20: '15.000 - 20.000 €',
      below25: '20.000 - 25.000 €',
      below30: '25.000 - 30.000 €',
      above30: "'Il fuq minn 30.000 €",
      below12700: 'Taħt 12.700 €',
      below21200: '12.700 - 21.200 €',
      below28700: '21.200 - 28.700 €',
      below60: '28.700 - 60.000 €',
      above60: "'Il fuq minn 60.000 €",
      low_income: 'Baxx (taħt il-15.000,00 €)',
      middle_income: 'Nofsani (fi 15.000,00 € - 37.000,00 €)',
      high_income: "Għoli ('il fuq minn 37.000,00 €)",
      low_household_income: 'Baxx (taħt it-30.000,00 €)',
      middle_household_income: 'Nofsani (fi 30.000,00 € - 75.000,00 €)',
      high_household_income: "Għoli ('il fuq minn 75.000,00 €)",
    },
    activity: [
      { text: 'Uri l-istudenti kollha', value: null },
      { text: 'Uri studenti attivi biss', value: 'yes' },
      { text: 'Uri biss studenti mhux attivi', value: 'no' },
    ],
    clearFilters: 'Ċara l-filtri kollha',
    clearSelections: 'Ċara l-għażliet kollha',
    exportToCsv: 'Esportazzjoni lejn CSV',
    searchStudents: 'Fittex studenti',
    viewNote: 'Ara n-nota',
  },
  StudentProfile: {
    riskLevel: "Livell ta 'riskju",
    dob: 'DOB',
    class: 'Klassi',
    gender: 'Sess',
    eduLevel: "Livell ta 'Edukazzjoni",
    conditions: 'Kundizzjonijiet',
    employer: 'Min iħaddem',
    livesWithParents: 'Jgħix mal-ġenituri / kustodji',
    fatherEduLevel: "Il-livell ta 'edukazzjoni ta' Missier / Gwardjan",
    motherEduLevel: "Livell ta 'Edukazzjoni ta' Omm / Gwardjan",
    personalIncome: 'Dħul personali',
    householdIncome: 'Dħul fid-djar',
    Tabs: {
      personal: 'Personali',
      results: 'Riżultati',
      events: 'Avvenimenti',
    },
    Events: {
      studentsEvents: 'AVVENIMENTI TAL-ISTUDENTI',
      search: 'Tfittxija',
      listEmpty: 'Il-lista hija vojta',
      estimatedRisk: 'Riskju stmat',
      riskLevel: "Livell ta 'riskju",
      LOW: 'BAXX',
      MEDIUM: 'MEDJU',
      HIGH: 'GĦOLI',
      noToLowRisk: 'Le għal riskju baxx',
      mediumRisk: 'Riskju medju',
      highRisk: 'Riskju għoli',
      selectEventType: "Agħżel it-tip ta 'avveniment",
      selectRiskFactor: 'Agħżel Fattur tar-Riskju',
      title: 'Titlu',
      description: 'Deskrizzjoni',
      uploadError: "Żball seħħ waqt li ttella 'fajl magħżul",
      EventTypes: {
        Custom: 'Drawwa',
        'Estimated Risk': 'Riskju stmat',
      },
    },
  },
  QuestionnaireViewer: {
    active: 'Attiv',
  },
  Psychometrics2: {
    questionnaires: 'Kwestjonarji',
    psychometrics2: 'Psikometrija 2',
    riskEstimate: 'Stima tar-riskju',
    question: 'Mistoqsija',
    of: "ta '",
    finish: 'Spiċċa',
    overview: 'Ħarsa ġenerali',
    previous: 'Preċedenti',
    next: 'Sussegwentement',
    cues: 'Cues',
    follow_up: "Mistoqsijiet ta 'segwitu",
    answer: 'Tweġiba',
    infoText:
      "Jekk jogħġbok agħżel l-imġieba li osservajt f'kull kategorija.Jekk jogħġbok innota wkoll kwalunkwe osservazzjoni addizzjonali li taqa 'taħt din il-kategorija / kostruzzjoni.Jekk jogħġbok agħżel grad wieħed għall-kostruzzjoni (baxx, medju, għoli) ibbażat fuq l-informazzjoni.Id-deċiżjoni għandha tittieħed skont evidenza kumulattiva.",
    riskEstimateText:
      "Jekk jogħġbok agħti l-istima tar-riskju tiegħek għat-tbassir ta 'qabel.Int tkun tista 'teditja din l-istima ladarba tispiċċa l-predikaturi kollha.",
    riskFactorsOverview: 'Ħarsa ġenerali tal-Fatturi tar-Riskju',
    predictor: 'Tbassir',
    riskFactor: "Fattur ta 'riskju",
    additionalNote: 'Nota addizzjonali',
    nextPredictor: 'Tbassir li jmiss',
    finishAndNext: 'Tispiċċa u mur waħda li jmiss?',
    finishInfoText:
      "Temm din il-linja ta ’mistoqsijiet u agħti l-fattur ta’ riskju.Int tkun tista 'terġa' lura u tbiddel dan.",
    finishTest: 'Itemm it-test',
    cancel: 'Ikkanċella',
    yes: 'Iva',
    atRisk: "F'riskju",
    notAtRisk: "Mhux f'riskju",
    skip: 'Aqbeż',
    none: 'Xejn',
    notePlaceholder: 'Agħżel biex tidħol nota tal-kwestjonarju',
    Answer: {
      Low: 'Baxx',
      Medium: 'Medju',
      High: 'Għoli',
    },
  },
};
