<template>
  <v-dialog :value="dialog" :persistent="true" max-width="480px">
    <v-card>
      <v-card-title
        class="text-h5"
        :class="{
          'grey lighten-2': !$vuetify.theme.dark && bgColor === 'default',
          'secondary darken-2': $vuetify.theme.dark,
          [`bg-${bgColor}-main`]: bgColor && bgColor !== 'default' && !$vuetify.theme.dark,
        }"
        >{{ $t('TenantsDialog.title') }}</v-card-title
      >
      <p v-if="tenantError" class="red lighten-1 white--text text-center font-weight-bold py-1">{{ tenantError }}</p>
      <v-list :class="{ [`bg-${bgColor}-light`]: bgColor && bgColor !== 'default' && !$vuetify.theme.dark }">
        <v-list-item-group
          color="primary"
          :value="activeTenant"
          @change="setActiveTenant"
          :mandatory="activeTenant != null"
        >
          <v-list-item
            v-for="tenant in Object.keys(tenants)"
            :key="tenant"
            :disabled="tenants[tenant].role === roles.TEACHER && ![roles.ADMIN, roles.COUNSELLOR].includes(role)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ tenant }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions :class="{ [`bg-${bgColor}-main`]: bgColor && bgColor !== 'default' && !$vuetify.theme.dark }">
        <v-btn @click="logout" elevation="0">{{ $t('TenantsDialog.logout') }}</v-btn>
        <v-btn @click="closeDialog" elevation="0" :disabled="activeTenant === null" class="ml-auto">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AzureAuth from '@/plugins/AzureAuth';
import { roles } from '@/utils/utils';

export default {
  data() {
    return {
      roles,
    };
  },
  computed: {
    dialog() {
      return !this.$store.getters.activeTenant || this.$store.getters.changingTenant;
    },
    tenants() {
      return this.$store.getters.tenants;
    },
    activeTenant() {
      const activeTenant = this.$store.getters.activeTenant;
      if (!activeTenant) return null;
      return Object.values(this.$store.getters.tenants).findIndex(t => t.key === activeTenant);
    },
    tenantError() {
      return this.$store.getters.tenantError;
    },
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor() {
      if (!this.$store.state.User.settings.common) return 'default';
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    setActiveTenant(newVal) {
      if (newVal === null || newVal === undefined) return;
      this.$store.dispatch('setActiveTenant', Object.values(this.$store.getters.tenants)[newVal].key);
    },
    closeDialog() {
      this.$store.dispatch('setChangingTenant', !this.dialog);
    },
    async logout() {
      await this.$store.dispatch('logout');
      AzureAuth.logout();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_colors.scss';
</style>
