import { Module } from 'vuex';
import { AxiosError } from 'axios';
import { i18n } from '@/main';
import router from '@/router';
import API from '@/plugins/axios';

export interface UserState {
  isLoggedIn: boolean;
  tnc_resolved: boolean;
  userDataFetched: boolean;
  clusterDataFetched: boolean;
  userData: Partial<UserData>;
  settings: Partial<Settings>;
}

export interface UserData {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  display_name: string;
  bio_info: string;
  image_url: string;
  role: string;
  permissions: string[];
  level_approximation: null;
  tnc_accepted: boolean;
  tnc_accepted_at: string | null;
  clusterData: {};
}

export interface Settings {
  prosys: ProsysSettings;
  common: CommonSettings;
}

export interface ProsysSettings {
  font_size: number;
  font_family: string;
}

export interface CommonSettings {
  color_theme: string;
  color_accent: string;
  language: string;
  badges_visible: string;
}

type Payload<T extends keyof Settings, V extends Partial<Settings[T]>> = [T, V];

const User: Module<UserState, {}> = {
  state: {
    isLoggedIn: false,
    tnc_resolved: false,
    userDataFetched: false,
    clusterDataFetched: false,
    userData: {
      tnc_accepted: false,
      tnc_accepted_at: null,
      role: '',
    } as UserData,
    settings: {},
  },
  actions: {
    getCurrentUser: async ({ commit, state }) => {
      let tnc_accepted, user_id;
      if (!state.userDataFetched) {
        let userData;
        try {
          const { data } = await API.get('v0/auth/user/self/');
          userData = data;
          tnc_accepted = data.tnc_accepted;
          user_id = data.user_id;
        } catch (e) {
          if ((e as AxiosError)?.response?.status == 403) {
            await API.get(`v0/auth/register/azure-ad/`);
            const { data } = await API.get('v0/auth/user/self/');
            userData = data;
            tnc_accepted = data.tnc_accepted;
            user_id = data.user_id;
          }
        }
        commit('setUserData', userData);
      }
      if (!state.clusterDataFetched && (tnc_accepted || state.userData.tnc_accepted)) {
        let user;
        try {
          const { data } = await API.get(`classter/users/${user_id || state.userData.user_id}/`);
          user = data;
        } catch {
          user = {};
        }
        commit('setClusterData', user);
      }
    },
    getSettings: async ({ commit, dispatch, state }) => {
      if (!state.userData.tnc_accepted) return;
      const { data } = await API.get<Settings>('/v0/auth/user/settings/');
      if (!Object.keys(data).length) {
        data.common = {
          color_accent: 'WaikawaGray',
          color_theme: 'Classic',
          language: 'en',
          badges_visible: '',
        };
        await dispatch('updateSettings', ['common', data.common]);
      }
      if (!data.prosys) {
        data.prosys = {
          font_size: 1,
          font_family: 'Roboto',
        };
        await dispatch('updateSettings', ['prosys', data.prosys]);
      }
      const fontSize = data.prosys?.font_size || 1;
      const fontFamily = data.prosys?.font_family || 'Roboto';
      document.documentElement.style.fontSize = `${fontSize * 16}px`;
      document.documentElement.style.fontFamily = `"${fontFamily}", sans-serif`;
      commit('setSettings', data);
      i18n.locale = data.common.language;
    },
    updateSettings: async ({ commit, state }, payload: [keyof Settings, Record<string, unknown>]) => {
      commit('updateSettings', payload);
      const { status } = await API.put('/v0/auth/user/settings/', state.settings);
      if (status === 200) {
        if (payload[0] === 'common' && payload[1].language) {
          i18n.locale = payload[1].language as string;
        }
      }
    },
    setTOSAccepted: async ({ commit }, payload = true) => {
      await API.post('/v0/auth/user/self/tnc_accept/');
      commit('acceptTOS', payload);
    },
    logout: ({ commit }) => {
      commit('logout');
    },
  },
  mutations: {
    setUserData: (state, payload: UserState['userData']) => {
      state.userData = payload;
      state.userDataFetched = true;
      state.tnc_resolved = true;
      state.isLoggedIn = true;
    },
    setClusterData: (state, payload) => {
      state.userData.clusterData = payload;
      state.clusterDataFetched = true;
    },
    setSettings: (state, payload: Settings) => {
      if (!payload.prosys) {
        payload.prosys = {
          font_size: 1,
          font_family: 'Roboto',
        };
      }
      state.settings = {
        ...payload,
      };
    },
    updateSettings: (state, payload: [keyof Settings, Record<string, unknown>]) => {
      let settings = state.settings[payload[0]];
      if (!settings) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state.settings[payload[0]] = {} as any;
        settings = state.settings[payload[0]];
      }
      Object.assign(settings, {}, settings, payload[1]);
    },
    acceptTOS: (state, payload) => {
      state.userData.tnc_accepted = payload;
    },
    logout: state => {
      state.isLoggedIn = false;
      state.tnc_resolved = false;
      state.userData = {};
      state.settings = {};
      router.push('/');
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    userDataFetched: state => state.userDataFetched,
    clusterDataFetched: state => state.clusterDataFetched,
    userData: state => state.userData,
    clusterData: state => state.userData.clusterData,
    settings: state => state.settings,
    tnc_resolved: state => state.tnc_resolved,
    tnc_accepted: state => state.userData.tnc_accepted,
    isCreator: state =>
      Object.keys(state.userData).length > 0
        ? (state.userData as UserData).permissions.includes('IS_PROSYS_COUNCILLOR')
        : false,
  },
};

export default User;
