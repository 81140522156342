import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import Home from '@/views/Student/Home.vue';
import LayoutHeader from '@/views/Student/LayoutHeader.vue';
import AzureAuth from '@/plugins/AzureAuth';
import {
  roles,
  ROLES_ANSWERING_PSY2,
  ROLES_VIEW_ANALYSIS_RESULTS,
  ROLES_VIEW_STUDENT_DATA,
  ROLES_VIEW_STUDENT_RESPONSES_DEM,
} from '@/utils/utils';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      header: LayoutHeader,
    },
    beforeEnter: (from, to, next) => {
      if (ROLES_VIEW_ANALYSIS_RESULTS.includes(store.getters.activeTenantRole)) {
        next('/creator');
      } else {
        next();
      }
    },
  },
  {
    path: '/creator/students/preview/:user_id/:id',
    name: 'QuestionnairePreview',
    components: {
      default: () =>
        import(/* webpackChunkName: "Employee" */ '../views/Employee/StudentProfile/QuestionnaireViewer.vue'),
      header: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutHeader.vue'),
      sidebar: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutSidebar.vue'),
    },
    beforeEnter: (from, to, next) => {
      if (ROLES_VIEW_STUDENT_RESPONSES_DEM.includes(store.getters.activeTenantRole)) {
        next();
      } else {
        return false;
      }
    },
  },
  {
    path: '/creator/students/:id',
    name: 'Students',
    components: {
      default: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/StudentProfile/StudentProfile.vue'),
      header: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutHeader.vue'),
      sidebar: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutSidebar.vue'),
    },
    beforeEnter: (from, to, next) => {
      if (ROLES_VIEW_STUDENT_DATA.includes(store.getters.activeTenantRole)) {
        next();
      } else {
        return false;
      }
    },
  },
  {
    path: '/creator/psychometrics-2/:user_id/:id',
    name: 'Psychometrics',
    components: {
      default: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Psychometrics2/Questions.vue'),
      header: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutHeader.vue'),
      subheader: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Psychometrics2/Subheader.vue'),
    },
    beforeEnter: (from, to, next) => {
      if (ROLES_ANSWERING_PSY2.includes(store.getters.activeTenantRole)) {
        next();
      } else {
        return false;
      }
    },
  },
  {
    path: '/creator/question_builder/:id',
    name: 'QuestionnaireBuilder',
    components: {
      default: () =>
        import(/* webpackChunkName: "Employee" */ '../views/Employee/QuestionnaireBuilder/QuestionnaireBuilder.vue'),
      header: () =>
        import(/* webpackChunkName: "Employee" */ '../views/Employee/QuestionnaireBuilder/LayoutHeader.vue'),
      sidebar: () =>
        import(/* webpackChunkName: "Employee" */ '../views/Employee/QuestionnaireBuilder/LayoutSidebar.vue'),
    },
    // 4
    beforeEnter: (from, to, next) => {
      if (store.getters.activeTenantRole === roles.ADMIN) {
        next();
      } else {
        return false;
      }
    },
  },
  {
    path: '/creator/questionnaries',
    components: {
      default: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Questionnaries.vue'),
      header: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutHeader.vue'),
      sidebar: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutSidebar.vue'),
    },
    // 4
    beforeEnter: (from, to, next) => {
      if (store.getters.activeTenantRole === roles.ADMIN) {
        next();
      } else {
        return false;
      }
    },
  },
  {
    path: '/creator',
    name: 'Creator',
    components: {
      default: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Students.vue'),
      header: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutHeader.vue'),
      sidebar: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/LayoutSidebar.vue'),
    },
    children: [
      {
        path: '/',
        redirect: '/creator/students',
        component: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Students.vue'),
      },
      {
        path: '/creator/students',
        component: () => import(/* webpackChunkName: "Employee" */ '../views/Employee/Students.vue'),
      },
    ],
    beforeEnter: (from, to, next) => {
      if (ROLES_VIEW_ANALYSIS_RESULTS.includes(store.getters.activeTenantRole)) {
        next();
      } else if (store.getters.activeTenantRole === roles.STUDENT) {
        next('/');
      } else {
        return false;
      }
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      default: () => import(/* webpackChunkName: "Settings" */ '../views/Settings/Settings.vue'),
      header: LayoutHeader,
    },
    children: [
      {
        path: '/settings/appearance',
        component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings/Appearance.vue'),
      },
      {
        path: '/settings',
        redirect: '/settings/appearance',
      },
    ],
  },
  {
    path: '/questions/:qid',
    name: 'Questions',
    components: {
      default: () => import(/* webpackChunkName: "Questions" */ '../views/Student/Questions.vue'),
      header: () => import(/* webpackChunkName: "Questions" */ '../views/Student/QuestionerHeader.vue'),
    },
    beforeEnter: (from, to, next) => {
      if (store.getters.activeTenantRole === roles.STUDENT) {
        next();
      } else {
        return false;
      }
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

async function getCurrentUser(i: number, res: (value: unknown) => void, rej: (value: unknown) => void) {
  if (await AzureAuth.getToken()) {
    await store.dispatch('getCurrentUser');
    res(true);
  } else if (i < 10) {
    setTimeout(() => {
      getCurrentUser(i + 1, res, rej);
    }, 200);
  } else {
    rej(false);
  }
}

router.beforeEach(async (to, from, next) => {
  if (!store.getters.userDataFetched) {
    await new Promise((res, rej) => {
      getCurrentUser(0, res, rej);
    });
  }
  next();
});

export default router;
